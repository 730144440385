import { parseCookies } from 'nookies';
import { useTranslation as useTranslation_, UserConfig } from 'next-i18next';

import { i18n } from '../next-i18next.config';
import { isOutdoorVendor } from './utils/check';

const { vendorId } = parseCookies({}) ?? -1;
export default {
  i18n,
  interpolation: {
    escapeValue: false,
    format: (value: string | number, format?: string, lng?: string) => {
      if(typeof value === 'number') {
        if (format !== 'ordinal') return value.toString();
        if (lng === 'ja') {
          return value.toString();
        }
        const pr = new Intl.PluralRules(lng, { type: 'ordinal' });
        switch (pr.select(value)) {
          case 'one':
            return `${value}st`;
          case 'two':
            return `${value}nd`;
          case 'few':
            return `${value}rd`;
          default:
            return `${value}th`;
        }
      } else if (lng === 'ja' && format === 'd_or_b') {
        return isOutdoorVendor(+vendorId) ? '$t(broadcast)' : '$t(delivery)';
      }
      return value;
    },
  },
} as UserConfig;

export const useTranslation = useTranslation_;
