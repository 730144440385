/* eslint-disable @typescript-eslint/no-var-requires */
import Head from 'next/head';
import React, { useEffect, useState } from 'react';
import { AppProps } from 'next/app';
import { StateInspector } from 'reinspect';
import { appWithTranslation } from 'next-i18next';

import nextI18NextConfig, { useTranslation } from 'src/i18n';
import { parseCookies } from 'nookies';

/**
 * Copy-and-pasted from https://github.com/mui-org/material-ui/blob/master/examples/nextjs/pages/_app.js
 */
function Sword({ Component, pageProps }: AppProps) {
  const { i18n } = useTranslation();
  const mentainanceMode = false;
  const genieeAdmin = parseCookies().geniee_admin;
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    jssStyles?.parentElement?.removeChild(jssStyles);
  }, []);

  if (process.env.NODE_ENV !== 'production') {
    if(process.browser) {
      const { applyClientHMR } = require('i18next-hmr/client');
      applyClientHMR(i18n);
    } else {
      const { applyServerHMR } = require('i18next-hmr/server');
      applyServerHMR(i18n);
    }
  }
  if (mounted && mentainanceMode && !genieeAdmin) {
    return (
      <>
        <title>Site Maintenance</title>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <style
          dangerouslySetInnerHTML={{
            __html:
                        '\n      body { text-align: center; padding: 20px; font: 20px Helvetica, sans-serif; color: #333; }\n      @media (min-width: 768px){\n        body{ padding-top: 150px; }\n      }\n      h1 { font-size: 32px; }\n      article { display: block; text-align: left; max-width: 650px; margin: 0 auto; }\n      a { color: #dc8100; text-decoration: none; }\n      a:hover { color: #333; text-decoration: none; }\n    ',
          }}
        />
        <article>
          <h1>サーバーメンテナンス中</h1>
          <div>
            <p>只今の時間サーバーメンテナンスを実施しています。 当システムをご利用の皆様には申し訳ございませんが、メンテナンス完了までお待ちください。</p>
            <p style={{ margin: '0' }}>■メンテナンス予定時間</p>
            <p style={{ margin: '0' }}>2023/08/23 1:00 〜 2023/08/23 4:30</p>
            <p>※ 通常の広告配信には影響ありません。</p>
          </div>
        </article>
      </>

    );
  }
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
      </Head>
      <StateInspector name="App">
        <Component {...pageProps} />
      </StateInspector>
    </>
  );
}

export default appWithTranslation(Sword, nextI18NextConfig);
